// src/pages/ContactPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './ContactPage.css';

function ContactPage() {
    const navigate = useNavigate();  // Hook to programmatically navigate
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: ''
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const { name, email, message } = formData;

        // Replace with your actual form handling logic or API request
        const response = await fetch('/php/send-contact.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ name, email, message })
        });

        const result = await response.json();
        if (result.success) {
            navigate('/thank-you');
        } else {
            alert('There was an error sending your message.');
        }
    };

    return (
        <div className="contact-page">
            <h2>Contact LKO</h2>
            <div className="contactus-container">
                {/* Contact Information Sections */}
                <div className="contactus-box">
                    <div className="contactus-icon">
                        <i className="fas fa-map-marker-alt"></i>
                    </div>
                    <div className="contactus-text">
                        <h3>Address</h3>
                        <p>34, Wellington Road, <br></br>Eccles M30 0NP</p>
                    </div>
                </div>

                <div className="contactus-box">
                    <div className="contactus-icon">
                        <i className="fas fa-phone-alt"></i>
                    </div>
                    <div className="contactus-text">
                        <h3>Call</h3>
                        <p>
                            Mobile:
                            <a href="tel:+447368325136"> +44 7368 325136</a>
                        </p>
                        <p>
                            Landline:
                            <a href="tel:01616693636"> 01616693636</a>
                        </p>
                    </div>
                </div>

                <div className="contactus-box">
                    <div className="contactus-icon">
                        <i className="far fa-envelope"></i>
                    </div>
                    <div className="contactus-text">
                        <h3>E-mail</h3>
                        <p>
                            <a href="mailto:info@lkofoods.com">info@lkofoods.com</a>
                        </p>
                    </div>
                </div>

                <div className="contactus-box">
                    <div className="contactus-icon">
                        <i className="far fa-clock"></i>
                    </div>
                    <div className="contactus-text">
                        <h3>Opening Hours</h3>
                        <p>Monday - Saturday & Bank Holidays: 1pm - 9pm</p>
                        <p><span className='closed'>CLOSED:</span> Sunday</p>
                    </div>
                </div>

                {/* Contact Form */}
                <div className="form section-animated" id="message-form">
                    <div className="contactus-form">
                        <h3>SEND US A MESSAGE</h3>
                        <form onSubmit={handleSubmit}>
                            <div className="form-group">
                                <input
                                    type="text"
                                    name="name"
                                    id="name"
                                    placeholder="Name"
                                    required
                                    autoComplete="off"
                                    value={formData.name}
                                    onChange={handleChange}
                                />
                                <label htmlFor="name">Name</label>
                            </div>
                            <div className="form-group">
                                <input
                                    type="email"
                                    name="email"
                                    id="email"
                                    placeholder="Email Address"
                                    required
                                    autoComplete="off"
                                    value={formData.email}
                                    onChange={handleChange}
                                />
                                <label htmlFor="email">Email Address</label>
                            </div>

                            <div className="form-group">
                                <textarea
                                    rows="8"
                                    name="message"
                                    id="message"
                                    placeholder="Message"
                                    required
                                    autoComplete="off"
                                    value={formData.message}
                                    onChange={handleChange}
                                ></textarea>
                                <label htmlFor="message">Message</label>
                            </div>

                            <button type="submit" className="submit-btn cta-button primary-btn">
                                Submit
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ContactPage;
